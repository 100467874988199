import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import AppDataContext from 'app/app-data-context';
import styles from './index.module.scss';
import { titleCase } from 'title-case';
import useAuthLinks from '@nguyengg/lambdajs/auth-links';
export default function SiteIndex({ hideAppointments, hidePayments, }) {
    const { sites, user } = useContext(AppDataContext);
    const [loginHref] = useAuthLinks();
    if (!user?.username) {
        return (React.createElement(Alert, { className: "bg-warning-subtle text-warning-emphasis" },
            React.createElement(Alert.Heading, null, "You are not signed in"),
            React.createElement("p", null,
                "Only Hera Nails's employees can access this site.",
                React.createElement("br", null),
                "You must authenticate by",
                ' ',
                React.createElement(Alert.Link, { className: "text-warning-emphasis", href: loginHref }, "signing in"),
                ' ',
                "first.")));
    }
    let groups = new Set(user?.groups ?? []);
    return (React.createElement("ul", { className: styles.menu }, sites.map((site) => (React.createElement("li", { key: site },
        React.createElement("span", { className: "display-6" }, titleCase(site)),
        React.createElement("ul", null,
            !hideAppointments && (React.createElement("li", null, groups.has(`${site}_appointment_ro`) || groups.has(`${site}_appointment_fa`) ? (React.createElement("a", { href: `/appointments/${site}` }, "Appointments")) : (React.createElement("span", { className: "text-muted" }, "Appointments")))),
            !hidePayments && (React.createElement("li", null, groups.has(`${site}_payment_ro`) || groups.has(`${site}_payment_fa`) ? (React.createElement("a", { href: `/payments/${site}` }, "Payments")) : (React.createElement("span", { className: "text-muted" }, "Payments"))))))))));
}
